<template>
  <Layout>
    <Indicator
      v-if="indicator"
      :indicator="indicator"
    />
    <div
      v-else
      class="catalog-container"
      :class="device + '-catalog-container'"
    >
      <div class="header">
        <img
          src="@assets/cadyco-Indicateurs_black.png"
          alt=""
        >
        <h2
          v-if="currentTab === 'userCatalog'"
          v-t="'router.views.Catalog.userCatalogHeader'"
        />
        <h2
          v-else-if="currentTab === 'defaultCatalog'"
        >
          {{ $t('router.views.Catalog.defaultCatalogHeader') + ' ' + userProfile.title.toUpperCase() }}
        </h2>
      </div>

      <div class="catalog-menu">
        <CadycoIconOption
          v-if="currentTab === 'userCatalog'"
          :icon="require('@assets/icon_engrenage.svg')"
          :icon-white="require('@assets/engrenage_white.png')"
          icon-height="19px"
          alt="settings"
          @click="toggleAdminMode"
        />
        <div
          v-if="currentTab === 'userCatalog' && userProfile !== undefined"
          class="menu-separator"
        />
        <a
          v-if="currentTab !== 'defaultCatalog' && userProfile !== undefined"
          @click="changeCurrentTab('defaultCatalog')"
        >
          {{ $t('router.views.Catalog.defaultCatalog') }}
          <div
            v-if="newUserIndicators.length > 0"
            class="notification"
          >
            {{ newUserIndicators.length }}
          </div>
        </a>
        <a
          v-if="currentTab !== 'userCatalog'"
          @click="changeCurrentTab('userCatalog')"
        >
          {{ $t('router.views.Catalog.userCatalog') }}
        </a>
      </div>

      <NewIndicators
        v-if="currentTab === 'news'"
        :indicator-list="newUserIndicators"
      />
      <div class="catalog">
        <Catalog
          v-if="currentTab === 'userCatalog' && catalog"
          :admin-mode="adminMode"
          :catalog="catalog"
        />
        <Catalog
          v-else-if="currentTab === 'defaultCatalog' && profileCatalog"
          :admin-mode="adminMode"
          :is-default-catalog="true"
          :catalog="profileCatalog"
        />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/router/layouts/AuthenticatedLayout.vue'
import Indicator from '@components/Indicator/Catalog/Indicator'
import Catalog from '@components/Indicator/Catalog/Catalog'
import NewIndicators from '@components/Indicator/Catalog/NewIndicators'
import CadycoIconOption from '@components/Base/CadycoIconOption'

export default {
  name: 'UserCatalog',
  components: {
    CadycoIconOption,
    NewIndicators,
    Layout,
    Indicator,
    Catalog
  },
  inject: ['mq'],
  data () {
    return {
      adminMode: false,
      currentTab: 'userCatalog'
    }
  },
  computed: {
    device () {
      if (this.mq.phone) {
        return 'phone'
      } else if (this.mq.projectSizeLimit1) {
        return 'limit1'
      } else if (this.mq.projectSizeLimit2 || this.mq.tablet) {
        return 'limit2'
      } else {
        return 'desktop'
      }
    },
    catalog () {
      return this.$store.state.catalog.userCatalog
    },
    userProfile () {
      if (this.$store.state.auth.user) {
        if (Object.keys(this.$store.state.auth.user.profile).length === 0 && this.$store.state.auth.user.profile.constructor === Object) { // aka this.$store.state.auth.user.profile === {}
          return undefined
        } else {
          return this.$store.state.auth.user.profile
        }
      } else {
        return undefined
      }
    },
    newUserIndicators () {
      return this.$store.state.catalog.unreadProfileIndicators
    },
    profileCatalog () {
      return this.$store.state.catalog.profileCatalog
    },
    indicator () {
      let indicator
      if (this.$route.params.indicatorId) {
        if (this.currentTab === 'userCatalog') {
          if (this.catalog !== undefined) {
            for (const category of this.catalog.categoryList) {
              const i = category.indicatorList.map(item => item.indicatorId).indexOf(this.$route.params.indicatorId)
              if (i > -1) {
                indicator = category.indicatorList[i]
                return indicator
              }
            }
          }
        } else {
          for (const category of this.profileCatalog.categoryList) {
            const i = category.indicatorList.map(item => item.indicatorId).indexOf(this.$route.params.indicatorId)
            if (i > -1) {
              indicator = category.indicatorList[i]
              return indicator
            }
          }
        }
      }
      return undefined
    }
  },
  mounted () {
    // Get user Catalog
    this.$store.dispatch('catalog/initUserCatalog')
    // Get new user's indicator
    this.$store.dispatch('catalog/getUnreadProfileIndicators')
  },
  methods: {
    toggleAdminMode () {
      this.adminMode = !this.adminMode
    },
    changeCurrentTab (tabName) {
      this.adminMode = false
      if (tabName === 'defaultCatalog') {
        this.$store.dispatch('catalog/selectProfile', this.userProfile)
      } else if (tabName === 'userCatalog') {
        if (this.newUserIndicators.length > 0) {
          this.$store.dispatch('catalog/setNewIndicatorsAsRead')
        }
      }
      this.currentTab = tabName
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.catalog-container {
  position: relative;
  height: calc(100% - #{$desktop-search-bar-height});
  overflow: auto;
  padding: 30px 20px 0 20px;
}

.header {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  h2 {
    margin: 7px 0 0 14px;
    font-size: 1em;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  }

  img {
    height: 28px;
  }
}

.catalog-menu {
  height: 28px;
  position: absolute;
  top: 30px;
  right: 20px;
  display: flex;
  align-items: center;

  .menu-separator {
    height: 17px;
    width: 1px;
    margin: 0 20px;
    background-color: $color-menu-bg;
  }

  a{
    position: relative;
    font-weight: bold;
    color: $color-cadyco-dark-text;
    cursor: pointer;

    &:hover {
      color: $color-hover-cadyco;
    }

    .notification {
      position: absolute;
      top: -8px;
      right: -12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 15px;
      color: white;
      background-color: red;
      height: 14px;
      width: 14px;
      border-radius: 7px;
    }
  }
}

.limit2-catalog-container {
  .header {
    justify-content: left;
  }
}

.phone-catalog-container {
  padding: 0 10px 10px 10px;
  height: calc(100% - #{$mobile-menu-height});
  overflow: auto;

  .header {
    margin: 30px 0 25px 0;
  }

  .catalog-menu {
    position: static;
    height: 34px;
    width: 100%;
    padding: 0 10px;
    justify-content: space-between;

    .menu-separator {
      margin: 0;
    }
  }
}
</style>
