<template>
  <IndicatorList
    class="indicator-list"
    :indicator-list="indicatorList"
  />
</template>

<script>
import IndicatorList from '@components/Indicator/Library/IndicatorList'
export default {
  name: 'NewIndicators',
  components: { IndicatorList },
  props: {
    indicatorList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.indicator-list {
  padding: 50px;
}

</style>
